<template>
    <div class="modulo-email-page">
        <Breadcrumb titulo="Produtos" :items="item" backgroundColor="#ff0b0b"  />

        <div class="modulo-email-page-container-cartoes">
            <div class="modulo-email-page-cartoes" v-for="cartao in cartoes" :key="cartao.id">
                <router-link :to="cartao.link" class="modulos-email-page-router-link">
                    <cartao-dinamico :titulo="cartao.titulo" :subtitulo="cartao.subtitulo" :classe="cartao.classe" :cor="cartao.cor" :iconBootstrap="cartao.icon_bootstrap" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import CartaoDinamico from '@/components/cartao/CartaoDinamico.vue'

    export default {
        name: 'ModuloEmailPage',

        components: {
            Breadcrumb,
            CartaoDinamico,
        },

        data: function () {
            return {
                textoPesquisa: '',
                item: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    }
                ],
                cartoes: [
                    {
                        id: '1',
                        titulo: 'Categorias',
                        subtitulo: 'Lista e criação de categorias',
                        classe: 'fas fa-boxes',
                        link: '/produtos-categorias',
                        cor: '#ff0b0b',
                        icon_bootstrap: 'vazio'
                    },
                    {
                        id: '2',
                        titulo: 'Produtos',
                        subtitulo: 'Lista e criação de produtos',
                        classe: 'fas fa-box-open',
                        link: '/produtos-produtos',
                        cor: '#ff0b0b',
                        icon_bootstrap: 'vazio'

                    },
                ]
            }
        },
    }
</script>

<style scoped>
    .modulo-email-page-container-cartoes {
        width: 100%;
        display: flex;
        padding: 28px 12px;
    }

    .modulo-email-page-cartoes {
        width: 33%;
        padding: 10px;
    }

    .modulos-email-page-router-link {
        text-decoration: none;
        color: inherit;
    }
</style>